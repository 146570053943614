import React, { useState } from "react";
import "../Style/Footer.scss";
import axios from "axios";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import $ from "jquery";
import designLogo from '../assets/logo.png'

function Footer() {
  const [user, setUser] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
    role: "",
    interested: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const register = () => {
    const { name, email, message, phone, role, interest } = user;
    if (
      name &&
      email &&
      message &&
      phone &&
      isValidPhoneNumber(phone) &&
      role &&
      interest
    ) {
      console.log(user);
      axios
        .post("https://unesco-backend.onrender.com/contact", user)
        .then((res) => console.log(res));
    } else {
      alert("Invalid Input");
    }

    setUser({
      name: "",
      message: "",
      phone: "",
      email: "",
    });
  };
  return (
    <>
      <div className="Footer">
        <div className="footer-main">
          <div className="logosContainer">
            <img
              src="https://res.cloudinary.com/unesco-admin/image/upload/v1686730963/Logopit_1686414165618_bzzoz9_o2yiqp.png"
              id="Logo"
              alt="logo"
              onClick={() => {
                window.location.assign("/");
              }}
            />
          </div>
          <h1 id="clgname">
            GLOBAL NETWORK OF BIOETHICS, MEDICAL AND HEALTH PROFESSIONS EDUCATION
          </h1>
        </div>

        <div className="mainContainer">
          <div className="mainContainerDiv">
            <div className="mapContainer">
              <iframe
                title="Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3144.8191639541014!2d145.21273954031173!3d-37.98134933001378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad6141a9eeff9f3%3A0x45518a1f1003d301!2s71%20Cleeland%20St%2C%20Dandenong%20VIC%203175%2C%20Australia!5e0!3m2!1sen!2sin!4v1643950821064!5m2!1sen!2sin"
                className="Map"
                loading="lazy"
              ></iframe>

              <p>71 Cleeland Street , Melbourne, Victoria 3175, AU</p>
              <ul>
                <li>
                  <a
                    href="https://mail.google.com/mail/?view=cm&fs=1&to=info-edu@unesco-bioethics-chair.org"
                    target="_blank" rel="noreferrer"
                  >
                    <i className="fas fa-envelope"></i>
                    info-edu@unesco-bioethics-chair.org
                  </a>
                </li>
                <li>
                  <a href="/">
                    <i className="fas fa-globe"></i>
                    global-medical-bioethics-education.org
                  </a>
                </li>
              </ul>
            </div>

            <div className="vl" id="footervl"></div>

            <div className="sections">
              <div className="Zcontainer">
                {/* <div className="Zcontainerinner"> */}
                <div className="section">
                  <h4>Important Links</h4>
                  <ul>
                    <a
                      href="http://www.int-chair-bioethics.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <li>International Chair In Bioethics</li>
                    </a>
                    <a
                      href="https://globalbioethicsenquiry.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <li>Global Bioethics Enquiry</li>
                    </a>
                    <a
                      href="https://www.bioethicon.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <li>Bioethicon</li>
                    </a>
                    <a
                      href="https://www.ethosibmrc.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <li>ETHOSibmrc</li>
                    </a>
                    <a
                      href="https://www.wma.net/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <li>World Medical Association</li>
                    </a>
                    {/* <a href="" target="_blank" rel="noreferrer">
                      <li>Other Resources</li>
                    </a> */}
                  </ul>
                  {/* </div> */}

                </div>

                <div className="section">
                  <h4>Follow Us</h4>
                  <ul id="follow">
                    <li>
                      <a href="/" target="_blank" rel="noreferrer">
                        <i className="fab fa-facebook-square"></i>Facebook
                      </a>
                    </li>
                    <li>
                      <a href="/" target="_blank" rel="noreferrer">
                        <i className="fab fa-youtube"></i>Youtube
                      </a>
                    </li>
                    <li>
                      <a href="/" target="_blank" rel="noreferrer">
                        <i className="fab fa-instagram"></i>Instagram
                      </a>
                    </li>
                    <li>
                      <a href="/" target="_blank" rel="noreferrer">
                        <i className="fab fa-linkedin"></i>Linkedin
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="section">
                <div className="form">
                  <h4>Contact us</h4>

                  <form method="POST">
                    <input
                      type="text"
                      name="name"
                      value={user.name}
                      className="formInput"
                      onChange={handleChange}
                      placeholder="Name"
                    />

                    <input
                      type="email"
                      name="email"
                      value={user.email}
                      className="formInput"
                      onChange={handleChange}
                      placeholder="Email"
                    />
                    <PhoneInput
                      defaultCountry="IN"
                      countryCallingCodeEditable={false}
                      type="text"
                      name="phone"
                      value={user.phone}
                      className="formInput input-phone"
                      onChange={(e) => {
                        if (e !== undefined) {
                          isValidPhoneNumber(e)
                            ? $(".PhoneInputInput").removeClass("invalid")
                            : $(".PhoneInputInput").addClass("invalid");
                        }
                        setUser({
                          ...user,
                          phone: e,
                        });
                      }}
                      placeholder="Phone number"
                    />
                    <div className="d-flex dropd">
                      <select
                        name="role"
                        id="role"
                        className="formInput"
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>
                          Category
                        </option>
                        <option value="Teacher">Teacher</option>
                        <option value="Student">Student</option>
                        <option value="University Representative">
                          University Representative
                        </option>
                        <option value="Other">Other</option>
                      </select>

                      <select
                        name="interest"
                        id="interested"
                        className="formInput"
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>
                          Interested In
                        </option>
                        <option value="Courses">Courses</option>
                        <option value="Research">Research</option>
                        <option value="Conferences">Conferences</option>
                        <option value="Membership">Memberships</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>

                    <input
                      type="text"
                      name="message"
                      value={user.message}
                      className="formInput"
                      onChange={handleChange}
                      placeholder="Message"
                    />

                    <button
                      type="button"
                      id="submit"
                      onClick={register}
                      className="btn btn-outline-light btn-sm"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer>
        <p>Designed and developed by {"  "} </p>
        <a href="https://prathamrasal.tech/" target="blank">
          <img src={designLogo} alt="logo" />
        </a>
      </footer>
    </>
  );
}

export default Footer;

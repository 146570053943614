import React from "react";
import "../.././Style/AboutUs/gn.scss";
import OurTeam from "./OurTeam";
import Vision from "./Vision/Vision";
import WhyJoin from "./whyjoin/WhyJoin";
import Objectives from "./Objectives/Vision"
import History from "./History/History";
import ContactGN from './ContactGn/ContactUs'
import Memorial from "./Memorial";
function UCBSA() {
  return (
    <div className="gnamain">
      {/* <div className="gnatop">

        <div className="gnleft">
          <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1686730963/Logopit_1686414165618_bzzoz9_o2yiqp.png" alt="" />
        </div>
        <h3>GLOBAL NETWORK OF BIOETHICS, MEDICAL AND HEALTH PROFESSIONS EDUCATION, </h3><h3>MELBOURNE, AUSTRALIA</h3>
      </div> */}

      <div className="gnabottom">

        <div className="bottomcontent">
          <p>
          The Global Network of Medical, Health Professions and Bioethics Education (GNMHPB) was inaugurated on the 27th of December 2022 by Professor Ricardo Leon Borquez, current President of World Federation of Medical Education, in the Presence of Professor Ronald Harden, CEO AMEE and Editor of Medical Teacher the Chief Guest of the function, along with Dr William Pinsky President ECFMG USA and Professor David Gordon Past President WFME. Professionalism and ethical behaviour are now recognised as essential to practice of medicine and medical and health professions graduate must demonstrate the moral and ethical principles under-lying the profession including ethical decision making. The Global Network of Medical, Health and Bioethics Education is an international network of leaders of Medical, Health Professions and Bioethics Educators, associations and institutions dedicated to promoting and advancing Bioethics integrated in Medical and Health Professions Education.
          </p>
        </div>

        {/* <img src="https://res.cloudinary.com/unesco-admin/image/upload/v1686414213/Logopit_1686414165618_bzzoz9.png" alt="" /> */}
      </div>

      {/* <Memorial /> */}
      <OurTeam />
      <Vision />
      <History />
      <WhyJoin />
      <Objectives />
      <ContactGN />
    </div>
  );
}

export default UCBSA;
